// ======================================================================
// kirbykit.dev | hello@hashandsalt.com
// ======================================================================

// ======================================================================
// Modules
// ======================================================================

let galite = require('ga-lite')

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

// ======================================================================
// Custom Javascript
// ======================================================================

galite('create', 'UA-XXXXXXXX-X', 'auto')
galite('send', 'pageview')
