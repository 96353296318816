// ======================================================================
// KirbySlate | hello@hashandsalt.com
// ======================================================================

window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

require('./modules/ga.js')
// require('./modules/slick.js')
require('./modules/smartmenu.js')

// ======================================================================
// Custom Javascript
// ======================================================================

$(document).ready(function() {
  // Slides
  // $('.big-slides').slick({
  //   dots: false
  //
  // });

  // Main Menu
  $('#main-menu').smartmenus({
    mainMenuSubOffsetX: 0,
    subMenusSubOffsetX: 0,
    subMenusSubOffsetY: 0,
    subIndicators: false
  });
});
